import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/isr-framework/Login/IsrUserLogin'),
        meta: {
            title: "主页"
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/isr-framework/Login/IsrUserLogin'),
        meta: {
            title: "用户登陆"
        }
    },
    {
        path: '/index',
        name: 'homeIndex',
        component: () => import('@/views/isr-framework/menu/MenuMain'),
        meta: {
            title: "华策养车后台管理系统"
        },
        children: [
            {
                path: '/isradmin/config-baseconfig',
                name: 'configpara',
                component: () => import('@/views/isr-framework/config/ConfigParaManager'),
                meta: {
                    title: "基本配置参数管理"
                }
            },
            {
                path: '/isradmin/config-dropdown',
                name: 'configdropdown',
                component: () => import('@/views/isr-framework/config/ConfigDropdownManager'),
                meta: {
                    title: "下拉框参数管理"
                }
            },
            {
                path: '/isradmin/config-banner',
                name: 'configbanner',
                component: () => import('@/views/isr-framework/config/ConfigBannerManager'),
                meta: {
                    title: "轮播图管理"
                }
            },
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/isr-framework/WorkMain/IsrWorkMain'),
                meta: {
                    title: "工作台"
                }
            },
            {
                path: '/isradmin/config-setmenu',
                name: 'configmenu',
                component: () => import('@/views/isr-framework/config/ConfigMenuManager'),
                meta: {
                    title: "菜单设置"
                }
            },
            {
                path: '/isradmin/config-menu-record',
                name: 'configmenulog',
                component: () => import('@/views/isr-framework/config/ConfigMenuLogManager'),
                meta: {
                    title: "菜单配置记录"
                }
            },
            {
                path: '/isradmin/config-requestapi',
                name: 'menuapi',
                component: () => import('@/views/isr-framework/config/ConfigMenuApiManager'),
                meta: {
                    title: "API请求记录"
                }
            },
            {
                path: '/isradmin/config-adminuser',
                name: 'isradminuser',
                component: () => import('@/views/isr-framework/config/ConfigUserManager'),
                meta: {
                    title: "用户管理"
                }
            },
            {
                path: '/isradmin/config-rules',
                name: 'isrrole',
                component: () => import('@/views/isr-framework/config/ConfigRoleManager'),
                meta: {
                    title: "角色管理"
                }
            },
            {
                path: '/isradmin/config-agreement',
                name: 'isragreement',
                component: () => import('@/views/isr-framework/config/ConfigAgreeMent'),
                meta: {
                    title: "协议管理"
                }
            },
            {
                path: '/isradmin/config-helptype',
                name: 'helptype',
                component: () => import('@/views/isr-framework/config/ConfigHelpManager'),
                meta: {
                    title: "帮助类型"
                }
            },
            {
                path: '/isradmin/config-helpcontent',
                name: 'helpcontent',
                component: () => import('@/views/isr-framework/config/ConfigHelpContentManager'),
                meta: {
                    title: "帮助内容"
                }
            },
            {
                path: '/isradmin/config-city',
                name: 'city',
                component: () => import('@/views/isr-framework/config/ConfigLocationCity'),
                meta: {
                    title: "城市管理"
                }
            },
            {
                path: '/isradmin/config-province',
                name: 'province',
                component: () => import('@/views/isr-framework/config/ConfigLocationProvince'),
                meta: {
                    title: "省份管理"
                }
            },
            {
                path: '/isradmin/config-district',
                name: 'district',
                component: () => import('@/views/isr-framework/config/ConfigLocationDistrict'),
                meta: {
                    title: "地区管理"
                }
            },
            {
                path: '/isradmin/config-sms',
                name: 'sms',
                component: () => import('@/views/isr-framework/config/ConfigSmsManager'),
                meta: {
                    title: "短信验证管理"
                }
            },
            {
                path: '/isradmin/config-email',
                name: 'mlemail',
                component: () => import('@/views/isr-framework/config/ConfigEmailSmsManager'),
                meta: {
                    title: "邮箱验证管理"
                }
            },

            {
                path: '/isradmin/config-holiday',
                name: 'holiday',
                component: () => import('@/views/isr-framework/config/ConfigHolidayManager'),
                meta: {
                    title: "节假日管理"
                }
            },
            {
                path: '/isradmin/config-authkeyword',
                name: 'authkeyword',
                component: () => import('@/views/isr-framework/config/ConfigAuthkeywordManager'),
                meta: {
                    title: "审核关键字"
                }
            },
            {
                path: '/isradmin/config-express',
                name: 'express',
                component: () => import('@/views/isr-framework/config/ConfigExpressCompanyManager'),
                meta: {
                    title: "快递公司管理"
                }
            },
            {
                path: '/isradmin/config-notification',
                name: 'notification',
                component: () => import('@/views/isr-framework/config/ConfigNotificationManager'),
                meta: {
                    title: "消息通知"
                }
            },
            {
                path: '/isradmin/config-nation',
                name: 'nation',
                component: () => import('@/views/isr-framework/config/ConfigNationManager'),
                meta: {
                    title: "民族管理"
                }
            },
            {
                path: '/mlpmis-admin/account/go-logout-home',
                name: 'logout',
                component: () => import('@/views/isr-mlpmis/logoutListView'),
                meta: {
                    title: "已注销用户列表"
                }
            },
            {
                path: '/mlpmis-admin/account/userinfo/go-userinfo-home',
                name: 'loginuser',
                component: () => import('@/views/isr-mlpmis/loginUserListView'),
                meta: {
                    title: "注册用户列表"
                }
            },
            {
                path: '/mlpmis-admin/order/go-order-home',
                name: 'payview',
                component: () => import('@/views/isr-mlpmis/PayListView'),
                meta: {
                    title: "订单管理"
                }
            },
            {
                path: '/mlpmis-admin/projects/go-project-home',
                name: 'project',
                component: () => import('@/views/isr-mlpmis/ProjectHomeView'),
                meta: {
                    title: "项目管理"
                }
            },
            {
                path: '/mlpmis-admin/org/go-org-logoff-home',
                name: 'orgout',
                component: () => import('@/views/isr-mlpmis/OrgOutHistoryView'),
                meta: {
                    title: "注销组织"
                }
            },
            {
                path: '/mlpmis-admin/org/go-org-home',
                name: 'orglist',
                component: () => import('@/views/isr-mlpmis/OrgListView'),
                meta: {
                    title: "组织列表"
                }
            },
            {
                path: '/mlpmis-admin/system/right/go-allright-home',
                name: 'allright',
                component: () => import('@/views/isr-mlpmis/RuleListView'),
                meta: {
                    title: "权限配置管理"
                }
            },
            {
                path: '/mlpmis-admin/system/right/org-role-right/go-roleright-home',
                name: 'roleright',
                component: () => import('@/views/isr-mlpmis/RuleUserListView'),
                meta: {
                    title: "项目角色权限配置管理"
                }
            },
            {
                path: '/mlpmis-admin/system/config/go-tasktype-home',
                name: 'tasktype',
                component: () => import('@/views/isr-mlpmis/TaskTypeView'),
                meta: {
                    title: "任务类型"
                }
            },
            {
                path: '/mlpmis-admin/system/config/go-bugtype-home',
                name: 'bugtype',
                component: () => import('@/views/isr-mlpmis/BugsTypeView'),
                meta: {
                    title: "缺陷类型"
                }
            },
            {
                path: '/mlpmis-admin/system/config/go-doctype-home',
                name: 'doctype',
                component: () => import('@/views/isr-mlpmis/DocTypeListView'),
                meta: {
                    title: "文档类型"
                }
            },
            {
                path: '/mlpmis-admin/system/right/org-project-right/go-projectright-home',
                name: 'projectright',
                component: () => import('@/views/isr-mlpmis/RuleObjectView'),
                meta: {
                    title: "项目权限配置管理"
                }
            },
            {
                path: '/mlpmis-admin/account/download/go-downloadprice-home',
                name: 'vipstanderd',
                component: () => import('@/views/isr-mlpmis/VipStandardListView'),
                meta: {
                    title: "VIP下载价格管理"
                }
            },
            {
                path: '/mlpmis-admin/account/uservip/go-uservip-home',
                name: 'vipuser',
                component: () => import('@/views/isr-mlpmis/VipUserListView'),
                meta: {
                    title: "VIP会员管理"
                }
            },
            {
                path: '/mlpmis-admin/regex-template/go-regex-home',
                name: 'regex',
                component: () => import('@/views/isr-mlpmis/RegexTemplateView'),
                meta: {
                    title: "正则表达式"
                }
            },
            {
                path: '/mlpmis-admin/system/config/bugs/go-bugkpi-home',
                name: 'bugkpi',
                component: () => import('@/views/isr-mlpmis/BugKpiView'),
                meta: {
                    title: "缺陷绩效"
                }
            },
            {
                path: '/mlpmis-admin/system/logger/loginlog/go-logginlog-home',
                name: 'logginlog',
                component: () => import('@/views/isr-mlpmis/LoginLogListView'),
                meta: {
                    title: "登陆日志"
                }
            },
            {
                path: '/mlpmis-admin/system/logger/webillegal/go-webillegal-home',
                name: 'webillegal',
                component: () => import('@/views/isr-mlpmis/NoAccessLoginView'),
                meta: {
                    title: "非法访问"
                }
            },
            {
                path: '/mlpmis-admin/account/go-loginerr-home',
                name: 'loginerr',
                component: () => import('@/views/isr-mlpmis/ErrorLoginView'),
                meta: {
                    title: "登陆账号密码异常管理"
                }
            },
            {
                path: '/mlpmis-admin/system/tools/go-pdf2word-home',
                name: 'pdf2word',
                component: () => import('@/views/isr-mlpmis/PDFtoWordView'),
                meta: {
                    title: "PDF转换WORD监控"
                }
            },
            {
                path: '/mlpmis-admin/system/tools/go-pdf2img-home',
                name: 'pdf2img',
                component: () => import('@/views/isr-mlpmis/PDFtoIMGView'),
                meta: {
                    title: "PDF转换IMG监控"
                }
            },
            {
                path: '/mlpmis-admin/system/tools/go-img2ico-home',
                name: 'img2ico',
                component: () => import('@/views/isr-mlpmis/IMGtoICO'),
                meta: {
                    title: "IMG转ICO监控"
                }
            },
            {
                path: '/mlpmis-admin/system/email/go-email-home',
                name: 'email',
                component: () => import('@/views/isr-mlpmis/EmailView'),
                meta: {
                    title: "邮件任务管理"
                }
            },
            {
                path: '/mlpmis-admin/regex-template/go-regexsearch-home',
                name: 'regexsearch',
                component: () => import('@/views/isr-mlpmis/RegexSearchView'),
                meta: {
                    title: "正则表达式搜索记录"
                }
            },
            {
                path: '/mlpmis-admin/blogs/go-blogauth-home',
                name: 'blogauth',
                component: () => import('@/views/isr-mlpmis/BlogsAuthView'),
                meta: {
                    title: "公开博客审核"
                }
            },
            {
                path: '/mlpmis-admin/projects/go-projectauth-home',
                name: 'projectauth',
                component: () => import('@/views/isr-mlpmis/ProjectAuthView'),
                meta: {
                    title: "公开项目审核"
                }
            },
            {
                path: '/isradmin/madminlogger',
                name: 'madminlogger',
                component: () => import('@/views/isr-framework/config/AdminConfigLoginManager'),
                meta: {
                    title: "总后台登陆日志管理"
                }
            },
            {
                path: '/isradmin/sellerlogger',
                name: 'sellerlogger',
                component: () => import('@/views/isr-framework/config/AdminSellerLoginManager'),
                meta: {
                    title: "全部商户登入日志管理"
                }
            },
            {
                path: '/mlpmis-admin/system/go-feedback-home',
                name: 'feedback',
                component: () => import('@/views/isr-mlpmis/FeedbackView'),
                meta: {
                    title: "意见/建议"
                }
            },
            {
                path: '/mlpmis-admin/system/advert/go-advcrl-home',
                name: 'advcrl',
                component: () => import('@/views/isr-mlpmis/AdvertView'),
                meta: {
                    title: "广告列表"
                }
            },
            {
                path: '/mlpmis-admin/system/schedule/go-html-home',
                name: 'htmlhome',
                component: () => import('@/views/isr-mlpmis/HtmlControllerView'),
                meta: {
                    title: "HTML控制"
                }
            },
            {
                path: '/mlpmis-admin/system/advert/go-adv-home',
                name: 'advhome',
                component: () => import('@/views/isr-mlpmis/AdvControllerView'),
                meta: {
                    title: "广告控制"
                }
            },
            {
                path: '/mlpmis-admin/go-apply-reviewcode',
                name: 'reviewcode',
                component: () => import('@/views/isr-mlpmis/ReviewCodeView'),
                meta: {
                    title: "源码申请预览记录"
                }
            },
            {
                path: '/error',
                name: 'error',
                component: () => import('@/views/isr-framework/config/ErrorPage'),
                meta: {
                    title: "未知页面"
                }
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: history,
    routes
})

const noNeedLoginPage = ['login', 'homeIndex']
router.beforeEach((to, from, next) => {
    const routeExists = router.getRoutes().some(route => route.path === to.path)
    if (!routeExists) {
        next('/error')
    }
    const user = JSON.parse(localStorage.getItem('user'))
    if (noNeedLoginPage.includes(to.name)) {
        next()
        return;
    }
    if (user) {
        next()
    } else {
        next('/login')
    }
    if (user.rdshopname && to.meta.title) {
        document.title = user.rdshopname + to.meta.title
    }
    if (to.meta.title === '主页') {
        next('/login')
    }
})

export default router